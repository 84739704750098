
import { useTranslation } from 'react-i18next';
import useTooltips from '../../hooks/useTooltips.js';
import Slider from '../selector/Slider.js';
import CatalogLogic from '../../logic/CatalogLogic.js';
import StringUtil from '../../utils/StringUtil.js';
import Tooltip from './Tooltip.js';

import less from '../..//assets/images/less.svg';
import neutral from '../..//assets/images/neutral.svg';
import more from '../..//assets/images/more.svg';

import './NormalizedView.css';

const NormalizedViewEditable = ({ category, disabled = false, reviews = false, onChange }) => {

    /**
     * Page state
     */

    useTooltips();

    const { t } = useTranslation();
    const labels = t('common.normalized-view-labels');

    /**
     * Event handlers
     */

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(category, skill, level);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    return category && category.children?.map(skill => {

        return (
            <div key={skill.id} className='container border mb-3'>

                <table className='table table-normalized'>
                    <thead>
                        <tr>
                            <th className='text-start'>
                                {skill.label}
                            </th>
                            <th>
                                <div className='table-header'>
                                    <div>
                                        <Tooltip title={labels[0]}><img src={less} alt='less' /></Tooltip>
                                        <Tooltip title={labels[1]}><img src={neutral} alt='neutral' /></Tooltip>
                                        <Tooltip title={labels[2]}><img src={more} alt='more' /></Tooltip>
                                        <Tooltip title={labels[3]}><img src={more} alt='more more' /><img src={more} alt='more more' /></Tooltip>
                                    </div>
                                    <button
                                        type='button'
                                        className='btn btn-xs btn-danger'>
                                        {t('glossary.delete')}
                                    </button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            skill.children?.map((skill, i) => {

                                const level = CatalogLogic.getSkillLevel(category, skill);
                                const hasTooltip = !StringUtil.isEmpty(skill.description);

                                return (
                                    <tr key={skill.id}>
                                        <td className='text-start ps-4'>
                                        {
                                                hasTooltip
                                                    ? <Tooltip title={skill.description} content={skill.label} />
                                                    : skill.label
                                            }
                                        </td>
                                        <td>
                                            {
                                                reviews
                                                    ? <Slider min='1' max='4' value={level} disabled={disabled} userLevel={skill.userLevel} reviews={skill.reviews} onChange={(e) => handleLevelChange(skill, e.target.value)} />
                                                    : <Slider min='1' max='4' value={level} disabled={disabled} onChange={(e) => handleLevelChange(skill, e.target.value)} />
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        )
    })
}

export default NormalizedViewEditable;
