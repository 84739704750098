
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './TitleBar.css'

const TitleBar = ({ title, filter = {}, onChange, ...props }) => {

    const { t } = useTranslation();
    const [text, setText] = useState(filter.text ?? '');
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {

        const debounceTimer = setTimeout(() => { onChange({ ...filter, text }) }, 400);
        return () => clearTimeout(debounceTimer);

        // eslint-disable-next-line
    }, [text]);

    const handleTextChange = (e) => {
        const text = e.target.value;
        setText(text);
        setExpanded(text.length > 0);
    }

    const handleUnfocus = () => {
        if (text.length < 1) setExpanded(false);
    }

    return (
        <div {...props} className='title-bar'>

            <div>{title}</div>

            {
                filter.count === filter.total && filter.total > 0 &&
                <div className='counter'>({filter.count})</div>
            }
            {
                filter.count !== filter.total &&
                <div className='counter'>({filter.count}/{filter.total})</div>
            }

            <div>
                <input
                    type='search'
                    className='form-control text-field'
                    placeholder={t('glossary.search') + '...'}
                    value={text ?? ''}
                    style={{ width: (expanded ? '100%' : '8rem') }}
                    onChange={handleTextChange}
                    onBlur={handleUnfocus} />
            </div>

        </div>
    )
}

export default TitleBar