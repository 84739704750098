
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useCurriculumController from '../../../controllers/useCurriculumController.js';
import CurriculumView from '../../../components/curriculum/CurriculumView.js';

const ContextualCurriculum = ({ user, motion, show, onClose }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [catalog, setCatalog] = useState({});
    const [formations, setFormations] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const curriculumController = useCurriculumController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                const data = show && await curriculumController.getPrivateCurriculum(user.id, motion.id, auth.lang);
                
                setCatalog(data.catalog);
                setFormations(data.formations ?? []);
                setExperiences(data.experiences ?? []);
            }
            catch(e) {
                console.log(e);
            }
            finally {
                setLoaded(true);
            }

        })()    

        // eslint-disable-next-line 
    }, [auth.lang, show]);

    /**
     * Event handlers
     */

    return (
        <div className={'modal fade' + (show ? 'show' : '')} style={{ display: show ? 'block' : 'none' }}>

            <div className='modal-dialog modal-fullscreen'>
                <div className='modal-content'>

                    <div className='modal-header pe-5'>
                        <h3 className='modal-title'>{t('mycv.curriculum')}</h3>
                        <button type='button' className='btn-close' onClick={onClose}></button>
                    </div>

                    <div className='modal-body'>
                        <div className='container'>
                            { loaded && <CurriculumView user={user} catalog={catalog} formations={formations} experiences={experiences} showExpectedValues={true} /> }
                        </div>
                    </div>

                    <div className='modal-footer pe-5'>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-light' onClick={onClose}>{t('button.close')}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContextualCurriculum;
