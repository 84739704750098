
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import FilterDialog from './FilterDialog.js';

import filter_img from '../../assets/images/filter.svg';

import './SearchBar.css'

const SearchBar = ({ items, filter, showFilter = true, onSearch, ...props }) => {

    showFilter = (typeof showFilter === 'string') ? showFilter === 'true' : showFilter;

    const { t } = useTranslation();
    const [text, setText] = useState(filter.text ?? '');
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {

        const debounceTimer = setTimeout(() => { onSearch({ ...filter, search: text }) }, 400);
        return () => clearTimeout(debounceTimer);

        // eslint-disable-next-line
    }, [text]);

    const handleTextChange = (e) => {
        const text = e.target.value;
        setText(text);
        setExpanded(text.length > 0);
    }

    const handleUnfocus = () => {
        if (text.length < 1) setExpanded(false);
    }

    /*
    const handleFilter = (filter) => {
        onSearch && onSearch(filter);
    }
    */

    return (

        <div {...props} className={'d-flex flex-fill justify-content-end align-items-center search-bar' + (props.className ? ' ' + props.className : '')}>

            <input type='search' className='text-field form-control' placeholder={t('glossary.search') + '...'} value={text ?? ''} style={{ width: (expanded ? '100%' : '8rem') }} onChange={handleTextChange} onBlur={handleUnfocus} />

            {
                showFilter &&
                <button disabled type='button' className='btn btn-light' data-bs-toggle='modal' data-bs-target='#filter'><img src={filter_img} alt='filter' /></button>
            }
            {
                /*
                showFilter &&
                <FilterDialog id='filter' items={items} filter={filter} onFilter={handleFilter} />
                */
            }

        </div>
    )
}

export default SearchBar