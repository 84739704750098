
import { useEffect, useState } from 'react';
import useMotionController from '../../../controllers/useMotionController.js';
import { useTranslation } from 'react-i18next';

import WishesView from '../../../components/radar/WishesView.js';
import AxesView from '../../../components/radar/AxesView.js';
import RadarView from '../../../components/radar/RadarView.js';
import MatchingTable from './MatchingTable.js';

import table_img from '../../../assets/images/list.svg';
import radar_img from '../../../assets/images/map.svg';
import reset_img from '../../../assets/images/reset.svg';
import axes_img from '../../../assets/images/axes.svg';

import './TabMap.css';

const TabMap = ({ motion, wishes, axes, onChange, onShowCurriculum }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const [matchings, setMatchings] = useState([]);
    const [labels, setLabels] = useState({});
    const [resetRadar, setRadarReset] = useState();
    const [text, setText] = useState();
    const [expanded, setExpanded] = useState(false);

    const [showRadar, setShowRadar] = useState(true);
    const [showTable, setShowTable] = useState(false);

    const motionController = useMotionController();

    /**
     * Page lifecycle
     */

    const reload = async () => {
        const matchings = await motionController.getMatchingsForMotion(motion, wishes, text);
        setMatchings(matchings);
        setExpanded(text?.length > 2);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload() }, [wishes])

    // eslint-disable-next-line 
    useEffect(() => { if (!text || text?.length === 0 || text?.length > 2) reload() }, [text])

    // If axes change, refresh axes label
    useEffect(() => {

        const xaxis = motion.categories?.find(w => w.id === axes.x);
        const yaxis = motion.categories?.find(w => w.id === axes.y);

        setLabels({
            horizontal: xaxis ? xaxis.label : 'n/a',
            vertical:   yaxis ? yaxis.label : 'n/a'
        })

        // eslint-disable-next-line
    }, [axes])

    /**
     * Event handlers
     */

    const handleRadarInit = (radarReset) => {
        setRadarReset(() => radarReset); // useState cannot store function directly
    }

    const handleReset = () => {
        resetRadar && resetRadar(); // radar reset scale + position
    }

    const handleChange = (e) => {
        onChange(e);
    }

    const handleToogle = () => {
        setShowRadar(!showRadar);
        setShowTable(!showTable);
    }

    const handleTextChange = (e) => {
        setText(e.target.value);
    }

    const handleUnfocus = () => {
        setExpanded(false);
    }

    return (

        <div className='tabmap'>

            <AxesView id='axes' axes={axes} categories={motion.categories} onChange={(a) => handleChange({ axes: a })} />

            <div className={'border rounded p-2 me-3 collapse collapse-horizontal show'}>
                <WishesView wishes={wishes} onChange={handleChange} />
            </div>

            <div className='radar-view border rounded p-2 flex-fill'>

                <div className='radar-toolbar'>

                    <div className='btn-group'>
                        <button className='btn btn-light' onClick={handleToogle}><img src={showRadar ? table_img : radar_img} alt='radar' /></button>
                        <button className='btn btn-light' disabled={!showRadar} onClick={handleReset}><img src={reset_img} alt='reset' /></button>
                        <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#axes'><img src={axes_img} alt='axes' /></button>
                    </div>

                    <input
                            type='search'
                            className='form-control search-field ms-1'
                            placeholder={t('glossary.search') + '...'}
                            value={text ?? ''}
                            style={{ width: (expanded ? '100%' : '') }}
                            onChange={handleTextChange}
                            onBlur={handleUnfocus} />

                </div>

                {showRadar && <RadarView entity={motion} axes={axes} matchings={matchings} labels={labels} onInit={handleRadarInit} onSelect={onShowCurriculum} />}
                {showTable && <MatchingTable motion={motion} axes={axes} matchings={matchings} onSelect={onShowCurriculum} />}

            </div>

        </div>
    )
}

export default TabMap;
