
import axios from "axios";
import AppSettings from '../logic/AppSettings.js';

const BACKEND_URL = `${AppSettings.BACKEND_URL}/invitations`;

const InvitationService = () => {

    const getInvitations = async (user, filter = null) => {

        let query = user || filter ? '?' : '';
        if (user) query += 'user=' + user.id + (filter ? '&' : '');

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${BACKEND_URL}${query}`)).data.payload;
    }

    const create = async (survey, user) => {
        return (await axios.put(`${BACKEND_URL}/create?survey=${survey.id}`, user)).data.payload;
    }

    const createInvitables = async (survey, company, filter) => {
        return survey.id 
            ? (await axios.get(`${BACKEND_URL}/invitables?survey=${survey.id}&company=${company.id}&text=${filter}`)).data.payload
            : (await axios.get(`${BACKEND_URL}/invitables?company=${company.id}&text=${filter}`)).data.payload;
    }

    const getInvitation = async (id, lang) => {
        return (await axios.get(`${BACKEND_URL}/${id}?lang=${lang}`)).data.payload;
    }

    const update = async (invitation) => {
        return (await axios.put(`${BACKEND_URL}/${invitation.id}`, { invitation })).data.payload;
    }

    const completed = async (invitation) => {
        return (await axios.put(`${BACKEND_URL}/${invitation.id}/completed`)).data.payload;
    }

    return {
        create,
        createInvitables,
        getInvitation,
        getInvitations,
        update,
        completed
    }
}

export default InvitationService();