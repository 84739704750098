
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';

import useMotionController from '../../../controllers/useMotionController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import MotionDelete from './MotionDelete.js';
import CatalogLogic from '../../../logic/CatalogLogic.js';
import ContextualCurriculum from './ContextualCurriculum.js';

import DeclarativeView from '../../../components/catalog/DeclarativeViewEditable.js';
import NormalizedView from '../../../components/catalog/NormalizedViewEditable.js';
import LanguageView from '../../../components/catalog/LanguageViewEditable.js';

import TabOptions from './TabOptions.js';
import TabMap from './TabMap.js';

const MotionDetail = () => {

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const { t } = useTranslation();
    const { id } = useParams();
    const newmotion = id === 'new';
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [motion, setMotion] = useState({ categories: [] });
    const [wishes, setWishes] = useState([]);
    const [axes, setAxes] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [deletable, setDeletable] = useState(false);
    const [showCurriculum, setShowCurriculum] = useState(false);

    const motionController = useMotionController();

    /**
     * Page lifecycle
     */

    // First time, get user and its wishes + axes
    useEffect(() => { 
    
        (async () => {

            try {
                setLoaded(false);
    
                // Get the motion (or create a new one)
                const motion = await motionController.getMotion(id);

                // Check if it is deletable
                const deletable = await motionController.deletable(motion);

                // Update view state
                setMotion(motion);
                setWishes(motion.wishes);
                setAxes(motion.axes);
                setDeletable(deletable);
            }
            catch (e) {
                console.log(e);
            }            
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line
    }, [id, auth.lang]);

    // eslint-disable-next-line
    useEffect(() => { motionController.retranslate([motion]) }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleChange = (e) => {

        if (e.motion) {
            setMotion(e.motion);
            setWishes(e.motion.wishes);
            setAxes(e.motion.axes);

        }
        else if (e.wishes) {
            setWishes(e.wishes);
        }
        else if (e.axes) {
            setAxes(e.axes);
        }
        else if (e.category) {
            CatalogLogic.mergeCategory(motion.catalog, e.category);
            setMotion({ ...motion });
        }
    }

    const handleShowCurriculum = (user) => {
        setUser(user);
        setShowCurriculum(true);
    }

    const handleSave = async () => {
        await motionController.save({ ...motion, wishes, axes });
        navigate('/manager/motions');
    }

    const handleClone = async () => {
        await motionController.clone(motion);
        navigate('/manager/motions');
    }

    const handleCloneSnapshot = async () => {
        await motionController.clone2snapshot(motion);
        navigate('/manager/surveys');
    }

    const handleDelete = async () => {
        await motionController.remove(motion);
        navigate('/manager/motions');
    }

    const handleCancel = () => {
        navigate('/manager/motions');
    }

    return (

        <div className='container-fluid'>

            <LoadingIcon loading={!loaded} />
            <MotionDelete id='deleteMotion' motion={motion} onDelete={handleDelete} />
            <ContextualCurriculum user={user} motion={motion} show={showCurriculum} onClose={() => setShowCurriculum(false) } />

            <div className='row p-4'>

                <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='text-muted'>{t('glossary.motion')} - {motion.name}</h5>
                </div>

                <hr />

                <ul className='nav nav-tabs pt-2'>

                    <li>
                        <button className={'nav-link ' + (newmotion ? '' : ' active')} data-bs-toggle='tab' data-bs-target={'#tab' + (motion.categories.length + 1)}>
                            {t('motions.map')}
                        </button>
                    </li>

                    <li>
                        <button className={'nav-link' + (newmotion ? ' active' : '')} data-bs-toggle='tab' data-bs-target='#tab0' type='button'>
                            {t('surveys.options')}
                        </button>
                    </li>

                    {
                        motion.categories.map((category, i) => (
                            <li key={i}>
                                <button className='nav-link' data-bs-toggle='tab' data-bs-target={'#tab' + (i + 1)}>
                                    {category.label}
                                </button>
                            </li>
                        ))
                    }

                </ul>

                <div className='tab-content pt-4'>

                    <div id='tab0' className={'tab-pane fade' + (newmotion ? ' show active' : '')}>
                        <TabOptions motion={motion} onChange={handleChange} />
                    </div>

                    {
                        motion.categories.map((category, i) => {

                            const isDeclarative = CatalogLogic.isDeclarative(category);
                            const isNormalized = CatalogLogic.isNormalized(category);
                            const isLanguage = CatalogLogic.isLanguage(category);

                            return (
                                <div id={'tab' + (i + 1)} key={i} className='tab-pane fade'>
                                    {isDeclarative && <DeclarativeView category={category} onChange={(category) => handleChange({ category })} />}
                                    {isNormalized && <NormalizedView category={category} onChange={(category) => handleChange({ category })} />}
                                    {isLanguage && <LanguageView category={category} onChange={(category) => handleChange({ category })} />}
                                </div>
                            )
                        })
                    }

                    <div id={'tab' + (motion.categories.length + 1)} className={'tab-pane fade' + (newmotion ? '' : ' show active')}>
                        <TabMap motion={motion} wishes={wishes} axes={axes} onChange={handleChange} onShowCurriculum={handleShowCurriculum} />
                    </div>

                </div>
            </div>

            <div className='row'>

                <hr />

                <div className='d-flex justify-content-between pb-4'>

                    <div className='btn-group'>
                        <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#deleteMotion' disabled={!deletable}>{t('button.delete')}</button>
                    </div>

                    <div className='btn-group'>
                        <button className='btn btn-light' onClick={handleSave}>{t('button.save')}</button>
                        <button className='btn btn-light' disabled={!deletable} onClick={handleClone}>{t('button.clone')}</button>
                        <button className='btn btn-light' disabled={!deletable} onClick={handleCloneSnapshot}>{t('glossary.survey')}</button>
                        <button className='btn btn-light' onClick={handleCancel}>{t('button.cancel')}</button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default MotionDetail;
