
import { useTranslation } from 'react-i18next';
import CatalogLogic from '../../logic/CatalogLogic.js';

import expected_img from '../../assets/images/expected-skill.svg';
import actual_img from '../../assets/images/actual-skill.svg';
import expected_actual_img from '../../assets/images/expected-actual-skill.svg';
import less from '../..//assets/images/less.svg';
import neutral from '../..//assets/images/neutral.svg';
import more from '../..//assets/images/more.svg';

import './NormalizedView.css';

const NormalizedView = ({ category, showExpectedValues }) => {

    /**
     * Page state
     */
    const { t } = useTranslation();
    const labels = t('common.normalized-view-labels');

    return category && category.children?.map(skill => (

        <div key={skill.id} className='container mb-3'>

            <table className='table table-hover table-normalized border'>
                <thead>
                    <tr>
                        <th className='text-start'>
                            {skill.label}
                        </th>
                        {
                            showExpectedValues &&
                            <th className='text-center'>
                                {t('mycv.differential-level')}
                            </th>
                        }
                        <th>
                            <div className='table-header'>
                                <div>
                                    <img src={less} alt='less' />
                                    <img src={neutral} alt='neutral' />
                                    <img src={more} alt='more' />
                                    <div><img src={more} alt='more more' /><img src={more} alt='more more' /></div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        skill.children?.map((skill) => {

                            const isLeaf = CatalogLogic.isLeaf(skill);
                            const actualLevel = CatalogLogic.getSkillLevel(category, skill);
                            const expectedLevel = CatalogLogic.getSkillExpectedLevel(category, skill);
                            const gap = actualLevel - expectedLevel;

                            return (
                                <tr key={skill.id}>

                                    <td className='text-start ps-4' style={{ width: '25%' }}>
                                        {skill.label}
                                    </td>

                                    {
                                        showExpectedValues && isLeaf &&
                                        <td className='text-center'>
                                            {
                                                !skill.level &&
                                                <span className='badge rounded-circle bg-warning'>!</span>
                                            }
                                            {
                                                skill.level && gap < 0 &&
                                                <span className='badge rounded-circle bg-danger'>{Math.abs(gap)}</span>
                                            }
                                            {
                                                skill.level && gap === 0 &&
                                                <span>-</span>
                                            }
                                            {
                                                skill.level && gap > 0 &&
                                                <span className='badge rounded-circle bg-success'>{Math.abs(gap)}</span>
                                            }
                                        </td>
                                    }

                                    <td>
                                        <div className='table-cell text-center'>
                                            {
                                                labels.map((x, i) => (
                                                    <div key={i}>
                                                        {
                                                            showExpectedValues && expectedLevel - 1 === i && expectedLevel !== actualLevel &&
                                                            <img src={expected_img} className='skill-icon' alt='expected' />
                                                        }
                                                        {
                                                            showExpectedValues && actualLevel - 1 === i && expectedLevel !== actualLevel &&
                                                            <img src={actual_img} className='skill-icon' alt='actual' />
                                                        }
                                                        {
                                                            showExpectedValues && actualLevel - 1 === i && expectedLevel === actualLevel &&
                                                            <img src={expected_actual_img} className='skill-icon' alt='expected + actual' />
                                                        }
                                                        {
                                                            !showExpectedValues && actualLevel - 1 === i &&
                                                            <img src={actual_img} className='skill-icon' alt='actual' />
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </td>

                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

        </div>
    ))
}

export default NormalizedView;
