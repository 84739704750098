
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import useModalEffect from "../../../hooks/useModalEffect.js";
import useInvitationController from '../../../controllers/useInvitationController.js';

import InvitationLogic from "../../../logic/InvitationLogic.js";
import SearchBar from '../../../components/filter/SearchBar.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';

import './InvitationAdd.css';

const InvitationAdd = ({ id, survey, onAdd }) => {

    const { t } = useTranslation();

    const defaultFilter = {
        search: null,
        attributes: [
            { name: 'userEmail', display: t('users.email') },
            { name: 'userFirstname', display: t('users.firstname') },
            { name: 'userLastname', display: t('users.lastname') },
            { name: 'userCompanyName', display: t('users.company') }
        ]
    }

    /**
     * Page state
     */

    const modalRef = useRef();
    const [filter, setFilter] = useState(defaultFilter);
    const [invitations, setInvitations] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [oneSelected, setOneSelected] = useState(false);

    const invitationController = useInvitationController();

    /**
     * Page lifecycle
     */

    const update = async (invitations) => {

        const allSelected = InvitationLogic.isAllSelected(invitations);
        const oneSelected = InvitationLogic.isOneSelected(invitations);

        setAllSelected(allSelected);
        setOneSelected(oneSelected);
        setInvitations([...invitations]);
    }

    const reload = async (filter) => {

        const text = filter ? filter.search : null;

        const invitations = await invitationController.createInvitables(survey, text);
        const newInvitations = InvitationLogic.filterNewInvitations(survey, invitations);

        update(newInvitations);
    }

    // eslint-disable-next-line
    useModalEffect(modalRef, async () => reload());

    // eslint-disable-next-line
    useEffect(() => { reload(filter) }, [filter]);

    /**
     * Event handlers
     */

    const handleToggleOne = (invitation) => {
        InvitationLogic.toggleSelected(invitation);
        update(invitations);
    }

    const handleToggleAll = () => {
        InvitationLogic.markAsSelected(invitations, !allSelected);
        update(invitations);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    const handleAdd = () => {
        const selectedInvitations = InvitationLogic.getSelected(invitations);
        onAdd(selectedInvitations);
        setFilter({ ...defaultFilter });
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-fixed modal-lg modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('invitations.add')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>
                        <div className='container pb-3'>
                            <div className='row p-4'>

                                <div className='d-flex align-items-center pb-4'>
                                    <SearchBar className='ms-4' items={invitations} filter={filter} showFilter='false' onSearch={handleFilter} />
                                </div>

                                <div className='table-fixed'>

                                    <table className='table table-hover table-sm'>
                                        <thead>
                                            <tr>
                                                {/* <th>
                                                    <SortableHeader attrName='userEmail' filter={filter} onFilter={handleFilter}>
                                                        {t('users.email')}
                                                    </SortableHeader>
                                                </th> */}
                                                <th>
                                                    <SortableHeader attrName='userFirstname' filter={filter} onFilter={handleFilter}>
                                                        {t('users.firstname')}
                                                    </SortableHeader>
                                                </th>
                                                <th>
                                                    <SortableHeader attrName='userLastname' filter={filter} onFilter={handleFilter}>
                                                        {t('users.lastname')}
                                                    </SortableHeader>
                                                </th>
                                                <th>
                                                    <SortableHeader attrName='userTeam' filter={filter} onFilter={handleFilter}>
                                                        {t('users.team')}
                                                    </SortableHeader>
                                                </th>
                                                <th>
                                                    <SortableHeader attrName='userCompanyName' filter={filter} onFilter={handleFilter}>
                                                        {t('glossary.company')}
                                                    </SortableHeader>
                                                </th>
                                                <th className='text-center'>
                                                    <input type='checkbox' className='form-check-input' checked={allSelected} onChange={handleToggleAll} />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                invitations.map((invitation, i) => (
                                                    <tr key={i} className='align-middle'>
                                                        {/* <td>{invitation.user.email}</td> */}
                                                        <td>{invitation.user.firstname}</td>
                                                        <td>{invitation.user.lastname}</td>
                                                        <td>{invitation.user.team}</td>
                                                        <td>{invitation.user.company.name}</td>
                                                        <td className='text-center'><input type='checkbox' className='form-check-input' checked={invitation.selected ?? false} onChange={() => handleToggleOne(invitation)} /></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>

                        <div className='modal-footer'>
                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' disabled={!oneSelected} onClick={handleAdd}>{t('button.add')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default InvitationAdd;
